<template>
  <div class="module" v-show="show">
    <slot :data="result" :empty='empty' :loading="loading" :totalResult="totalResult"></slot>
    <loading :options="loadOption" v-if="showLoading && loading"></loading>
    <div class="empty" v-if="empty">
        <!-- <img src="https://www.bzwz.com/static/m/images/icon36.png" class="focus_img"> -->
        <img src="@/assets/img/common/icon36.png" alt="" class="focus_img">
        <div>暂无数据
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Loading from "../UI/Loading/index";

import usePageScroll, { PropsType } from "@/hooks/useWxPageScroll";

export default defineComponent({
  props: {
    filterFunc: Function,
    url: String,
    params: Object,
    show: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    }
  },
  components: {
    Loading,
  },
  setup(props) {
    const { loading, result , empty, totalResult } = usePageScroll(props as PropsType);
      const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      result,
      loading,
      loadOption,
      empty,
      totalResult
    };
  },
});
</script>


<style lang="scss" scoped>
.module {
  margin-bottom: 130px;
}
.empty {
    padding-top: 160px;
    text-align: center;
    padding-bottom: 50px;
    color: #9f9f9f;
    font-size: 14px;
}
.empty img {
    width: 132px;
    height: 104px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
}
</style>