<template>
  <main>
    <div  class="swiper-container swiper-loading skeleton"></div>

    <div class="swiper-container banner-swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" >

          <img style="width:100%;height: 70px;" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/9365c3684436a09f1422013c7a01bba5.png"  />

        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
    <menu-tab
            :menu="menu"
            :slidesPerView="2"
            :initIndex="type"
            @change="selectMenu"
    ></menu-tab>

    <list
            url="/M/User/Order"
            :show="currentMenuItem == menu[0]"
    >
      <template v-slot:default="data">
        <order-item :order="data"></order-item>
      </template>
    </list>

    <wx-card
            url="/M/User/NeiOrder"
            :show="currentMenuItem == menu[1]"
            :code="code"
            :state="state"
    >
      <template v-slot:default="data">
        <nei-item :neiorder="data"></nei-item>
      </template>
    </wx-card>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref, inject } from "vue";
  import dateFormat from "@/utils/dateFormat";
  import MenuTab from "@/components/common/MenuTab.vue";
  import OrderItem from "@/components/common/OrderItem.vue";
  import neiItem from "@/components/common/NeiItem.vue";
  import { calendarFormat } from "@/utils/dateFormat";
  import { useRoute, onBeforeRouteLeave } from "vue-router";
  import list from "@/components/common/list.vue";
  import WxCard from "@/components/common/WxCard.vue";
  import router from "@/router";
  export default defineComponent({
    name: "Order",
    components: {
      MenuTab,
      OrderItem,
      list,
      WxCard,
      neiItem
    },

    setup() {
      type Menu = "待确认" | "正式订单" ;
      const menu: Menu[] = ["待确认", "正式订单"];
      const route = useRoute();
      const type = ref(Number(route.query.type || 1));
      const showRedPackage = ref(
              localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      const currentMenuItem = ref(menu[Number(type.value)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      const code = route.query.code
      const state = route.query.state


      onBeforeRouteLeave(() => {
        showRedPackage.value = false;
        return true;
      });

      const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
      function outLogin() {
        localStorage.removeItem("token");
        clearUserInfo();
        router.push({
          name: "Index",
        });
      }
      return {
        menu,
        currentMenuItem,
        selectMenu,
        dateFormat,
        calendarFormat,
        type,
        showRedPackage,
        outLogin,
        code,
        state
      };
    },
  });
</script>


<style lang="scss" scoped>
  main {
    padding-bottom: 50px;
  }

  /**

      菜单导航
    */
  .menu-nav {
    overflow: hidden;
    height: 45px;
    line-height: 45px;
    border-bottom: 6.5px solid #f4f4f4;
  }

  .menu-nav li {
    float: left;
    width: 50%;
    text-align: center;
  }
  .menu-nav li a {
    display: block;
    text-align: center;
    line-height: 45px;
    width: 100%;
    font-size: 16px;
    color: #999;
  }
  .menu-nav .active a {
    color: #df0024;
  }


  .order-ul li {
    font-size: 14px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 15px;
    /* padding: 0 15px;*/
    /* padding-left: 70px;*/
    padding-bottom: 15px;
    border-bottom: 5.6px solid #f4f4f4;
    position: relative;
  }
  .order-ono{
    color: #000;
    padding-left:0.4rem !important;
    /*padding-bottom: 50px;*/
    margin-bottom: 12px;
    display: inline-block;
  }
  .order-ono span{
    color: #000;
  }

  .order-no {
    text-decoration: underline;
    color: rgb(61, 194, 214);
  }

  .nei-order-num{
    float: left;
    padding-left: 0.4rem !important;
    color: #999;
  }
  .nei-order-num span{
    padding-left: 5px;
    color: #333;
  }
  .nei-order-personal{
    float: left;
    padding-left: 0.4rem !important;
    color: #999;
  }
  .sales-record {
    color: rgb(61, 194, 214);
  }
  .spend-date {
    /*position: absolute;*/
    left: 15px;
    /*top: 40px;*/
    color: #999;
    display: inline-block;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
  }
  .spend-date span {
    font-size: 22px;
    text-align: center;
    display: block;
  }
  .spend-date p {
    font-size: 14px;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }
  .order-ul .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    color: #444;
    position: relative;
  }
  .price {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    color: rgb(102, 102, 102);
  }
  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*padding: 5px 0 5px 0;*/
    /*padding-left: 10px;*/
    font-size: 14px;
    box-sizing: border-box;
    color: #999;
    position: relative;
  }
  .order-btn {
    position: absolute;
    right: 0;
    text-align: center;
    width: 80px;
    height: 22.5px;
    border-radius: 15px;
    line-height: 22.5px;
    font-size: 14px;
    color: #fff;
    background-color: #aa183c;
    border: solid 1px #aa183c;
  }
  .saleOrder-status{
    display: inline-block;
    float: right;
    padding-right: 20px;
    /*padding-top: 10px;*/
  }
  .saleOrder-date{

  }
  .saleOrder-detail{
    display: inline-block;
    float: right;
    padding-top: 20px;
  }
  .saleOrder-btn {
    float: right;
    right: 0;
    text-align: center;
    width: 80px;
    height: 25px;
    border-radius: 15px;
    line-height: 25px;
    font-size: 14px;
    color: #aa183c;
    background-color: #fff;
    border: solid 1px #aa183c;
  }

  .saleOrder-ul li {
    box-sizing: border-box;
    padding: 0 15px;
    padding-bottom: 15px;
    overflow: hidden;
    /*padding-top: 15px;*/
    border-bottom: 5.6px solid #f4f4f4;
    position: relative;
  }
  .saleOrder-title{
    font-size: 15px !important;
    color: #333 !important;
    margin-bottom: 10px !important;
  }
  .saleOrder-title img{
    position: relative;
    top: 4px;
    margin-right: 5px;
  }
  /**
  询价单样式
   */

  .inquiry-order-ul li {
    font-size: 14px;
    box-sizing: border-box;
    padding:15px 0 15px 15px;
    overflow: hidden;
    border-bottom: 5.6px solid #f4f4f4;
    position: relative;
    color: #999;
  }
  .inquiry-order-no{
    color: #999;
    display: inline-block;
  }

  .order-right{
    float: right;
    padding-right: 16px;
  }
  .inquiry-order-status{
    display: inline-block;
    color: #df0024;
  }

  .inquiry-order-content{
    height:75px;
  }

  .inquiry-order-date {
    width: 60px;
    height: 75px;
    padding-top: 9px;
    display: inline-block;
    float: left;
    color: #999;
    margin-right: 12px;
    text-align: center;
  }

  .inquiry-order-date span {
    display: inline-block;
    font-size: 0.5rem;
    color: #999999;
    text-align: center;
    height: 0.6rem;
    line-height: 0.5rem;
    font-weight: bold;
  }

  .inquiry-order-date p {
    text-align: center;
    color: #999999;
    line-height: 0.5rem;
  }

  .inquiry-right-content {
    color: #999;
  }

  .inquiry-order-no {
    color: #333;
  }

  .inquiry-right-content>div {
    height: 25px;
    line-height: 25px;
  }

  .inquiry-price {
    color: #d64b4b;
    float: right;
  }
  /*
  .inquiry-order-content{
    height:90px ;
    padding-top: 12px;
    line-height: 16px;
  }
  .inquiry-order-content img {
    width: 70px;
    height: 70px;
    display: inline-block;
    float: left;
    margin-right: 12px;
  }*/
  .inquiry-order-title{
    color: #333;
    display: inline-block;
    font-size: 15px !important;
    padding-bottom: 10px;
    width: 266px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .inquiry-order-spec{
    padding-bottom: 10px;
    color: #999;
  }
  .inquiry-order-spec .spec1{
    display: inline-block;
    padding-right: 10px;
  }
  .inquiry-order-spec .spec2{
    display: inline-block;
    padding-right: 10px;
  }

  .inquiry-order-update-date{
    color: #999;
    float: left;
    display: inline-block;
  }
  .inquiry-order-personal{
    display: inline-block;
  }

  .inquiry-order-num{
    padding: 0px 16px 10px 240px;
    color: #999;
  }

  .kefu-btn{
    display: inline-block;
    text-align: center;
    width: 2rem;
    height: 0.6rem;
    border-radius: 0.4rem;
    line-height: 0.6rem;
    font-size: 0.37301rem;
    color: #df0034;
    border: solid 0.02667rem #df0024;
  }

  .get_packet {
    float: right;
    margin-right: 20px;
    padding: 0 0.21333rem;
    height: 0.6rem;
    line-height: 0.6rem;
    background-color: #d64b4b;
    color: #fff;
    border-radius: 0.6rem;
    text-align: center;

    position: absolute;
    right: 0;
    bottom: 17px;
  }
</style>
