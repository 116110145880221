
  import { defineComponent, ref, inject } from "vue";
  import dateFormat from "@/utils/dateFormat";
  import MenuTab from "@/components/common/MenuTab.vue";
  import OrderItem from "@/components/common/OrderItem.vue";
  import neiItem from "@/components/common/NeiItem.vue";
  import { calendarFormat } from "@/utils/dateFormat";
  import { useRoute, onBeforeRouteLeave } from "vue-router";
  import list from "@/components/common/list.vue";
  import WxCard from "@/components/common/WxCard.vue";
  import router from "@/router";
  export default defineComponent({
    name: "Order",
    components: {
      MenuTab,
      OrderItem,
      list,
      WxCard,
      neiItem
    },

    setup() {
      type Menu = "待确认" | "正式订单" ;
      const menu: Menu[] = ["待确认", "正式订单"];
      const route = useRoute();
      const type = ref(Number(route.query.type || 1));
      const showRedPackage = ref(
              localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      const currentMenuItem = ref(menu[Number(type.value)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      const code = route.query.code
      const state = route.query.state


      onBeforeRouteLeave(() => {
        showRedPackage.value = false;
        return true;
      });

      const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
      function outLogin() {
        localStorage.removeItem("token");
        clearUserInfo();
        router.push({
          name: "Index",
        });
      }
      return {
        menu,
        currentMenuItem,
        selectMenu,
        dateFormat,
        calendarFormat,
        type,
        showRedPackage,
        outLogin,
        code,
        state
      };
    },
  });
